.drawer-footer {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .textarea-container {
    flex: 1 1 100%; /* Take full width on smaller screens */
    margin-bottom: 15px;
  }
  
  .textarea-container.maximised {
    width: 80%;
  }
  
  .comment-textarea {
    width: 100%; /* Ensure the textarea always takes full width */
  }
  
  .button-container {
    flex: 1 1 100%; /* Take full width on smaller screens */
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 10px; /* Add spacing between buttons */
  }
  
  .button-container.maximised {
    justify-content: space-around; /* Center-align buttons when maximised */
  }
  
  .approve-button,
  .reject-button {
    flex: 0 0 auto; /* Buttons should not stretch */
  }
  .expand-button {
    display: block;
  }
  @media (max-width: 768px) {
    .expand-button {
      display: none !important;
    }
    .button-container {
      justify-content: space-between; /* Adjust button alignment for mobile */
      flex-wrap: wrap; /* Wrap buttons to the next line */
    }
  
    .approve-button,
    .reject-button {
      flex: 1 1 100%; /* Make buttons full width */
      text-align: center;
    }
  
    .textarea-container {
      width: 100%; /* Ensure full width on smaller screens */
    }
  }