.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Space between cards */
  width: 98%;
}

.card {
  border: 1px solid #ddd; /* Light border around the card */
  border-radius: 8px; /* Rounded corners */
  padding: 1rem; /* Padding inside the card */
  background-color: #fff; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.fui-Checkbox__indicator {
  margin: 0 !important;
}
.cardRow {
  display: flex;
  margin-bottom: 0.5rem; /* Space between rows */
  justify-content: space-between; /* Ensure label and value are separated */
  
}
.oddRow {
  background-color: #fff; /* Light gray background for odd rows */
}
.cardLabel {
  font-weight: bold;
  color: #333;
  margin-right: 0.5rem;
  flex: 1; /* Allow the label to take up available space */
}

.cardValue {
  color: #555;
  flex: 2; /* Allow the value to take up more space */
}

.card:last-child {
  margin-bottom: 0; /* Remove margin on the last row */
}
